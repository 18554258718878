import { AuthGuard, REPLEN_PATH_APP } from '@autone/ui';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { DashboardLayout } from '../layout/index';

// ---------------------- App Routes --------------- //

// AuthGuard checks if user has currently logged in or not when a user accesses any given page

const AppRoutes = {
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // an overview of all current and historic replenishments
    {
      exact: true,
      path: REPLEN_PATH_APP.overview,
      component: lazy(() => import('../views/Overview')),
    },
    {
      exact: true,
      path: REPLEN_PATH_APP.summary,
      component: lazy(() => import('../views/BatchSummary')),
    },
    // edit a specific batch id x distribution points
    {
      exact: true,
      path: REPLEN_PATH_APP.editReplenishment,
      component: lazy(() => import('../views/EditReplenishment')),
    },
    {
      exact: true,
      path: REPLEN_PATH_APP.confirmedReplenishment,
      component: lazy(() => import('../views/SubmissionConfirmation')),
    },
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={REPLEN_PATH_APP.overview} />,
    },
    // if path is not recognised then redirect back to the homepage
    {
      component: () => <Redirect to={REPLEN_PATH_APP.overview} />,
    },
  ],
};

export default AppRoutes;
