export const ERROR_CODES = {
  NO_INVENTORY_FOR_FROM_AND_TO_LOCATIONS: {
    header: 'No inventory data',
    description:
      'No current inventory data across both the distribution centre and receiving locations for your scope',
  },
  NO_INVENTORY_FOR_FROM_LOCATION: {
    header: 'No inventory data for distribution centre',
    description:
      'No current inventory data for your distribution centre for your scope',
  },
  NO_DISTRIBUTION_FLOW: {
    header: 'No distribution flow mapped',
    description:
      'No distribution flow has been mapped, please update your parameters',
  },
  NO_DISTRIBUTION_FLOW_FOR_FROM_LOCATION: {
    header: 'No distribution flow mapped for distribution centre',
    description:
      'No distribution flow has been mapped for your distribution centre, please update your parameters',
  },
  NO_TO_LOCATION: {
    header: 'No receiving locations exist',
    description:
      'No receiving locations are within your scope for your distribution centre, please update your parameters',
  },
  FROM_AND_TO_SAME_LOCATION: {
    header: 'Unable to replenish only to the same location',
    description:
      'No other locations in scope to receive stock, please update your parameters',
  },
  NO_PRODUCTS_IN_FROM_LOCATION: {
    header: 'No stock available in distribution centre',
    description:
      'No products available in your distribution centre for this scope',
  },
  UNABLE_TO_CALCULATE_LOCATION_DEPTH: {
    header: 'Unable to calculate minimum depth',
    description: 'Unable to calculate minimum depth, please contact support',
  },
  NO_SKUS: {
    header: 'No SKUs exist for products',
    description: 'Products within scope have no SKUs, please contact support',
  },
  NO_NEED_OR_RECENT_ACTIVITY: {
    header: 'No data for products in scope',
    description: 'No products in scope have a need or any recent activity',
  },
  NO_NEED: {
    header: 'No replenishment need',
    description: 'No replenishment need calculated',
  },
  NO_COVERAGE: {
    header: 'No coverage defined',
    description:
      'No coverage defined for receiving locations, please update parameters',
  },
  INTERNAL_ERROR: {
    header: 'An internal error occured',
    description: 'An undefined error has occured, please contact support',
  },
};
