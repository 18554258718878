import { AuthGuard, ErrorView, REPLEN_PATH_APP } from '@autone/ui';

import { ERROR_CODES } from '../constant/errorCodes';
import { PageLayout } from '../layout';

const ErrorRoute = {
  guard: AuthGuard,
  layout: PageLayout,
  path: '/replenishment/error/batch/:batchId/reasonCode/:reasonCode',
  routes: [
    {
      exact: true,
      path: REPLEN_PATH_APP.error,
      component: () => (
        <ErrorView module={'replenishment'} errorCodes={ERROR_CODES} />
      ),
    },
  ],
};

export default ErrorRoute;
